<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :color="'#1B1B1B'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    @mouseleave.native="drawerHover = false"
    @mouseover.native="drawerHover = true"
    mobile-break-point="960"
    app
    mini-variant-width="80"
    width="300"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <div
      style="position: relative;"
    >
      <v-list-item two-line>
        <v-list-item-content
          align="center"
          justify="center"
        >
          <router-link to='/validacoes/dashboard'>
            <v-img
              v-if="drawerExpanded || (drawerHover && !drawerExpanded)"
              width="144" height="60"
              src="@/assets/log_branco_norven.png"
            />
            <v-img
              v-else
              width="48" height="20"
              src="@/assets/log_branco_norven.png"
            />
          </router-link>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-1" />

      <v-list
        dense
        nav
        id="drawer-section-profile"
      >
        <base-item-group v-if="profile" :item="profile" class="drawerItemList" :recolherSubMenus="!drawerHover ? false : true" />
      </v-list>

      <v-divider class="mb-2" />

      <div @click.stop="openDialogChangeCompany()">
        <v-list dense nav>
          <base-item :href="undefined" :item="selectedCompany"/>
        </v-list>
      </div>

      <v-divider class="mb-2" />

      <v-list
        expand
        nav
      >
        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />

        <v-list-item 
          v-if="userInfo && (userInfo.admin || userInfo.validador)"
          id="drawer-section-caixa-de-entrada" 
          href="#/caixa-de-entrada"
        >
          <v-list-item-icon class="v-list-item__icon--text">
            <v-badge
              :value="count_pendencias_not_done > 0"
              color="warning"
              bottom
              overlap
              :content="count_pendencias_not_done"
            >
              <v-icon>mdi-inbox</v-icon>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Caixa de Entrada
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-for="(item, i) in computedItems">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>

          <base-item
            v-else-if="item.hide !== true"
            :key="`item-${i}`"
            :item="item"
            :idElement="'drawer-section-'+item.title.toLowerCase().replace(/ /g, '')"
          />
        </template>

        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />
      </v-list>

      <v-divider class="mb-2" />
      
      <v-list
        expand
        nav
      >
        <v-list-item id="drawer-section-tutorial" :href="undefined" @click="forceInitTourCurrentPage()">
          <v-list-item-icon
            class="v-list-item__icon--text"
          >
            <v-icon v-text="'mdi-help-circle'"/>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="'Tutorial'" />
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item id="drawer-section-faq" href="#/faq">
          <v-list-item-icon class="v-list-item__icon--text">
            <v-icon v-text="'mdi-frequently-asked-questions'" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="'FAQ'" />
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
      <v-divider class="mb-1" style="margin-top:10px" />
    </div>
    <v-dialog v-model="dialogChangeCompany" max-width="340px" eager>
      <v-card>
        <v-card-title class="headline">Mudar Empresa</v-card-title>
        <v-card-text>
          <v-row class="mx-auto">
            <select-company 
              ref="refSelectCompany" 
              :changeCompany="false"
              @selected="handleCompanySelected"
              @companySelected="changeSelectedCompany($event)" 
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary" 
            @click="triggerSelectCompanyEvent()"
          >
            Confirmar
          </v-btn>
          <v-btn 
            color="primary" 
            text 
            @click.stop="dialogChangeCompany = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import AuthService from '@/services/AuthService'
import SelectCompany from '@/components/general/SelectCompany'
import CaixaDeEntradaService from "@/services/CaixaDeEntradaService.js";

export default {
  name: 'DashboardCoreDrawer',

  components: {
    SelectCompany
  },

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
    drawerExpanded: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.checkUserIsAdmin();
    this.getProfile();
    this.getCountUserPendenciasNotDone();
  },

  data: () => ({
    drawerHover: false,
    profilePic: null,
    profile: null,
    items: [
      // {
      //   icon: 'mdi-clipboard-text-outline',
      //   title: 'Relatórios',
      //   to: '/relatorios',
      //   hide: true
      // },
      {
        icon: 'mdi-text-box-check-outline',
        title: 'Validações',
        to: '/validacoes/dashboard',
      },
      {
        icon: 'mdi-view-dashboard-outline',
        title: 'Painéis Gerenciais',
        to: '/paineis-gerenciais',
      },
      // {
      //   icon: 'mdi-bank',
      //   title: 'Governança',
      //   to: '/governanca',
      // },
      {
        icon: 'mdi-database',
        title: 'Dados',
        to: '/dados'
      },
      {
        icon: 'mdi-cog',
        title: 'Administração',
        to: '/administracao',
        hide: true
      }
    ],
    itemTour: {
      icon: 'mdi-help-circle',
      title: 'Tutorial'
    },
    dialogChangeCompany: false,
    newSelectedCompanyId: null
  }),

  computed: {
    ...mapState(['barColor', 'barImage']),

    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },

    computedItems () {
      return this.items.map(this.mapItem)
    },

    getCssLogoPositionLogo() {
      let heightDrawer = document.getElementById('core-navigation-drawer');
      heightDrawer = heightDrawer ? heightDrawer.offsetHeight : 645;
      return heightDrawer <= 644 ? 'relative' : 'fixed';
    },

    selectedCompany() {
      return {
        sigla: this.$store.getters.getCompanyAcronym, 
        title: this.$store.getters.getCompanyName,
        id: this.$store.getters.getCompanyId
      };
    },

    userInfo() {
      return this.$store.getters.getUserInfo;
    },

    ...mapState("caixaDeEntrada", [
      "count_pendencias_not_done"
    ]),
  },

  watch: {
    '$vuetify.breakpoint.smAndDown' (val) {
      this.$emit('update:expandOnHover', !val)
    },
  },

  methods: {
    async getProfile () {
      this.profile =  {
        avatar: true,
        img: false,
        group: '/perfil',
        title: this.$store.getters.getUserName,
        children: [
          {
            icon: 'mdi-account',
            to: 'index',
            title: 'Perfil',
          },
          {
            icon: 'mdi-logout',
            to: 'logout',
            title: 'Logout',
          },
        ]
      }
    },

    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },

    forceInitTourCurrentPage() {
      let arrTourInfo = this.$store.getters.getTourInfo;
      let nomePagina = this.$router.history.current.name;

      arrTourInfo.forEach(tourInfo => {
        if (tourInfo.nome_pagina == nomePagina) tourInfo.tour_completado = false;
      });

      this.$store.dispatch('setTour', arrTourInfo);
      location.reload();
    },

    checkUserIsAdmin() {
      const adminsPages = [
        '/administracao',
        // '/relatorios',
        '/dados'
      ];
    
      AuthService.userIsAdmin()
        .then(userIsAdmin => {
          adminsPages.forEach(page => {
            const index = this.items.findIndex(el => el.to == page);

            this.items[index].hide = !userIsAdmin;
          });
        });
    },

    openDialogChangeCompany() {
      this.dialogChangeCompany = true;
      this.$refs.refSelectCompany.company = this.selectedCompany.id;
      this.newSelectedCompanyId = this.selectedCompany.id;
    },

    handleCompanySelected(companyId) {
      this.newSelectedCompanyId = companyId;
    },

    triggerSelectCompanyEvent() {
      this.$refs.refSelectCompany.companySelected(this.$refs.refSelectCompany.company);
    },

    changeSelectedCompany(company) {
      this.dialogChangeCompany = false;

      this.$store.dispatch('setSelectedCompany', company);

      location.reload();
    },

    ...mapActions("caixaDeEntrada", [
      "setCountPendenciasNotDone"
    ]),

    getCountUserPendenciasNotDone() {
      CaixaDeEntradaService.getCountUserPendenciasNotDone()
        .then(res => {
          this.setCountPendenciasNotDone(res);
        })
        .catch(err => {
          console.error(err);
        });
    },
  }
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>